import {GetServerSideProps} from 'next';
import dynamic from 'next/dynamic';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import queryString from 'query-string';
import {setNextAuthCookies} from 'shared/platform/auth';
import {CONFIG} from 'shared/platform/config';
import {TNextServerPage} from 'shared/platform/page';

const Login = dynamic(() => import('login').then((mod) => mod.Login), {ssr: false});

const LoginPage: TNextServerPage = () => <Login />;

LoginPage.config = {title: 'Login', allowUnauthenticated: true};

export const getServerSideProps: GetServerSideProps = async ({req, res}) => {
  if (req.headers['sec-fetch-dest'] !== 'document') return {props: {}};

  const {
    query: {callbackUrl},
  } = queryString.parseUrl(req.url ?? '');

  setNextAuthCookies({
    res,
    cookies: [{key: 'next-auth.callback-url', value: String(callbackUrl ?? '/')}],
    maxAge: CONFIG.OAUTH_STATE_MAX_AGE_SECONDS,
  });

  if (process.env.LOGIN_HOST)
    return {
      redirect: {
        destination: `${process.env.LOGIN_HOST}/api/auth/redirect_with_jwt?callbackUrl=${encodeURIComponent(
          `${process.env.NEXTAUTH_URL}/api/auth/set_jwt`,
        )}`,
        permanent: false,
      },
    };

  return {
    props: await serverSideTranslations('en', ['login']),
  };
};

export default LoginPage;
